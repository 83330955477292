import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const MoreProjectsDivider = ({ id, ...props }) => {
  return (
    <div className="col-span-12 mt-8">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 text-lg font-semibold tracking-wide text-gray-500 bg-gray-100 dark:bg-dmblack-500 dark:text-white font-gotham">
            More Projects
          </span>
        </div>
      </div>
    </div>
  );
};

export default MoreProjectsDivider;
