import React from "react";

const H1ProjectTitle = ({ id, ...props }) => {
  return (
    <div className="mb-0">
      {props.center == null ? (
        <h1
          className="z-10 pb-3 text-4xl lg:text-6xl font-black tracking-tight font-whitney text-gnNavy-400 dark:text-white "
          id={props.uniqid}
          style={{ color: props.textcolor }}
        >
          {props.children}
        </h1>
      ) : (
        <h1
          className="z-10 pb-3 text-4xl lg:text-6xl font-black tracking-tight text-center font-whitney text-gnNavy-400 dark:text-white "
          style={{ color: props.textcolor }}
          id={props.uniqid}
        >
          {props.children}
        </h1>
      )}
    </div>
  );
};

export default H1ProjectTitle;
