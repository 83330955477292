import React from "react";

const YoutubeVideo = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="z-10 aspect-w-16 aspect-h-9">
    <iframe
      src={`https://www.youtube.com/embed/${videoSrcURL}`}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      className="z-10"
    />
  </div>
);
export default YoutubeVideo;
