import React from "react";



const SolidColorHeader = ({ id, ...props }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-80 lg:h-128 z-0" style={{background: props.color}}></div>
  );
};

export default SolidColorHeader;
