import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import tw, { styled } from "twin.macro";
import { darken } from "polished";

const HomeButtonProjects = styled(Link)`
  ${tw`z-10 text-base font-semibold cursor-pointer font-whitney`};
  color: ${(props) => props.color};
  &:hover {
    color: ${(props) => darken(0.3, props.color)};
  }
`;

const NavbarProjects = (props) => {
  return (
    <div className="grid gap-8 mt-16 tracking-wide md:gap-10 lg:gap-6 md:grid-cols-12">
      <div className="z-10 col-span-12">
        <HomeButtonProjects color={props.color} to="/">
          <FontAwesomeIcon icon={faChevronLeft} /> Home
        </HomeButtonProjects>
      </div>
    </div>
  );
};

export default NavbarProjects;
