import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const MoreProjectsCard = ({ id, ...props }) => {
  return (
    <div className="md:col-span-4">
      <div className="relative h-64">
        <Link to={props.link} className="group">
          <div className="absolute z-40 hidden transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 group-hover:block ">
            <h3 className="text-xl font-black text-white font-gotham">
              {props.title}
            </h3>
          </div>
          <div className="absolute top-0 left-0 z-20 hidden w-full h-full group-hover:bg-opacity-75 group-hover:bg-gray-800 group-hover:block"></div>
          <GatsbyImage
            image={props.projectMainImage.gatsbyImageData}
            className="absolute top-0 left-0 z-10 object-cover w-full h-full shadow-xl filter dark:brightness-95"
            alt={props.alt}
          />
        </Link>
      </div>
    </div>
  );
};

export default MoreProjectsCard;
