import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import config from "../utils/siteconfig";
import Container from "../components/container";
import Navbar from "../components/small-navbar";
import { GatsbyImage } from "gatsby-plugin-image";
import MoreProjectsCard from "../components/pages/moreProjectsCard";
import H1ProjectTitle from "../components/shared/h1ProjecTtitle";
import SolidColorHeader from "../components/shared/headerSolidColor";
import MoreProjectsDivider from "../components/pages/moreProjectsDivider";
import YoutubeVideo from "../components/pages/youtubeVideo";
import NavbarProjects from "../components/smallNavbarProjects";
import Transition from "../components/pages/infoModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import HomepageButton from "../components/pages/homepageButton";

const Project = ({ props, data }) => {
  const projects = data.Projects;
  const projectsImages = data.Projects.supportingImages;
  const moreProjects = data.AllProjects.edges;
  const {
    primaryColor,
    secondaryColor,
    optionalThirdColor,
    youtubeVideoLink,
    title,
  } = data.Projects;
  const { contentType } = data.Projects;
  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>

      <Container>
        <NavbarProjects
          color={optionalThirdColor}
          color2={optionalThirdColor}
        />
        <SolidColorHeader color={secondaryColor} />
        <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
          <div className="z-10 col-span-12">
            <H1ProjectTitle textcolor={primaryColor}>
              {projects.title}
            </H1ProjectTitle>
          </div>
          <div className="z-10 col-span-12 mt-12 lg:mt-28">
            {projectsImages == null ? (
              <YoutubeVideo
                videoSrcURL={youtubeVideoLink}
                videoTitle="Official Music Video on YouTube"
              />
            ) : (
              <div className="space-y-16">
                {projectsImages.map((image) => {
                  return (
                    <TransformWrapper
                      initialScale={1}
                      doubleClickDisabled={true}
                    >
                      <TransformComponent>
                        <GatsbyImage
                          key={image.gatsbyImageData.id}
                          image={image.gatsbyImageData}
                          className="duration-500 ease-in-out transform shadow-xl hover:scale-105 motion-reduce:transition-none motion-reduce:transform-none filter dark:brightness-95"
                          // className="absolute top-0 left-0 z-10 object-contain w-full h-full shadow-xl"
                          alt={image.gatsbyImageData.alt}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  );
                })}
              </div>
            )}
          </div>
          <HomepageButton />
          <MoreProjectsDivider />
          <div className="col-span-12">
            <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
              {moreProjects.map(({ node }) => {
                return (
                  <MoreProjectsCard
                    id={node.id}
                    projectMainImage={node.mainImage}
                    alt={node.mainImage.title}
                    title={node.title}
                    link={`/projects/${node.slug}`}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query ($slug: String!) {
    Projects: contentfulWork(slug: { eq: $slug }) {
      slug
      title
      contentType
      youtubeVideoLink
      videoLength
      supportingImages {
        gatsbyImageData(width: 1408, quality: 90)
      }
      primaryColor
      secondaryColor
      optionalThirdColor
    }
    AllProjects: allContentfulWork(limit: 3) {
      edges {
        node {
          title
          slug
          mainImage {
            gatsbyImageData(width: 700)
          }
          childContentfulWorkDescriptionTextNode {
            description
          }
          description {
            description
          }
        }
      }
    }
  }
`;
