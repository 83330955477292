import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Navbar = (props) => {
  return (
    <div className="grid gap-8 mt-16 md:gap-10 lg:gap-6 md:grid-cols-12">
      <div className="z-10 col-span-12">
        <Link
          to="/"
          className="z-10 text-base font-semibold tracking-wide text-gray-500 cursor-pointer hover:text-gray-700 dark:text-gray-200 dark:hover:text-gray-400 font-whitney"
        >
          <FontAwesomeIcon icon={faChevronLeft} /> Home
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
